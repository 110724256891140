export const TUTORIAL_ZONE_PROJECT_QUERY = 'Tutorial Project Query';
export const TUTORIAL_ZONE_PROJECT_QUERY_CREATE_POINT =
  'Tutorial Work Zone - Create Point';
export const TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_NOT_COMPUTED =
  'Tutorial Work Zone - Get Statistics (Point Not Computed)';
export const TUTORIAL_ZONE_PROJECT_QUERY_GET_STATISTICS_POINT_COMPUTED =
  'Tutorial Work Zone - Get Statistics (Point Computed)';
export const TUTORIAL_ZONE_PROJECT_ANALYZE = 'Tutorial Project Analyze';
export const TUTORIAL_ZONE_PROJECT_ANALYZE_DISPLAY_STATISTICS =
  'Tutorial Project Analyze - Display Statistics';
